h1 {
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width*2;
  //font-weight: 400;
  line-height: 1;

  @include media-breakpoint-down(md) {
    font-size: $h1-font-size/1.9;
  }
}

h2 {
  margin-bottom: map-get($spacers, 5);

  > small {
    font-size: $h3-font-size;
    //color: $text-muted;
  }

  @include media-breakpoint-down(sm) {
    font-size: $h2-font-size/1.5;
  }
}

h3 {
  margin-bottom: $grid-gutter-width;
}

h4 {
  margin-bottom: $grid-gutter-width/3;
}

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 400;
}

.big-text {
  font-size: 24px;
  //font-weight: $font-weight-light;

  @include media-breakpoint-down(md) {
    font-size: 20px;
  }

  .item-list {
    font-size: $h4-font-size;
  }
}
